import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import { toast } from 'react-toastify'
import qp from 'query-parse'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Chip,
  CircularProgress,
  Dialog,
  Button
} from '@material-ui/core'
import { action, observable, runInAction } from 'mobx'
import { v1 as uuidv1 } from 'uuid'
import classnames from 'classnames'
import RaisedButton from '../../../shared/common/RaisedButton'
import alertMessages from '../../../../constants/alertMessages'
import {
  selectedFieldsById,
  selectedFields,
  generateLink,
  selectedFieldsGetIDs
} from '../../../../utils/helpers'
import SubscriptionSelectForm from './SubscriptionSelectForm'
import Suggest from './Suggest'
import SuccessfullSubscriptionModal from './SuccessfullSubscriptionModal'

@inject('rootStore', 'edmStore', 'agenciesStore', 'utilsStore')
@observer
class CreateJobAlert extends React.Component {
  @observable
  formDatas = null
  @observable
  copyUrl = ''

  constructor(props) {
    super(props)
    this.state = {
      stepIndex: 0,
      displaySuccessModal: false,
      suggestionErrorModal: ''
    }
    this.saveUrl()
    const queryData = qp.toObject(this.props.location?.search.slice(1)) ?? {}
    this.queryParam = {
      categoriesList: queryData.category?.split(',') || [],
      locationsList: queryData.location?.split(',') || [],
      consultantList: queryData.consultant?.split(',') || [],
      workTypeList: queryData.worktype?.split(',') || [],
      jobAlertCategory: queryData?.job_alert_category?.split(',') || [],
      jobAlertLocation: queryData?.job_alert_location?.split(',') || [],
      jobAlertWorkType: queryData?.job_alert_worktype?.split(',') || [],
      jobAlertSalaryCurrency: queryData?.job_alert_salary_currency,
      jobAlertSalaryFrom: queryData?.job_alert_salary_from,
      jobAlertSalaryTo: queryData?.job_alert_salary_to,
      rQuery: queryData?.rSearch?.split('|') || [],
      step: Number(queryData?.jobAlertStep) || 0,
      editSuggestionId: queryData?.['edit-suggestion-id']
    }

    if (this.queryParam.editSuggestionId) {
      this.setSuggestion(this.props)
    }

    this.state.stepIndex = this.queryParam.step === 0 ? 0 : 1
  }

  setSuggestion = (props) => {
    const { edmStore } = props
    const { editSuggestionId } = this.queryParam
    edmStore
      .setSuggestion(editSuggestionId)
      .then((res) => {
        this.queryParam.jobAlertCategory = res.categories || []
        this.queryParam.jobAlertLocation = res.locations || []
        this.queryParam.jobAlertWorkType = res.worktypes || []
        this.queryParam.rQuery = res.keywords || []
        this.queryParam.jobAlertSalaryCurrency = res.salary_currency
        this.queryParam.jobAlertSalaryFrom = res.salary_from
        this.queryParam.jobAlertSalaryTo = res.salary_to

        edmStore.fetchSelectedCategories(
          this.queryParam.categoriesList,
          this.queryParam.jobAlertCategory
        )

        edmStore.fetchSelectedLocations(
          this.queryParam.locationsList,
          this.queryParam.jobAlertLocation
        )

        edmStore.fetchSelectedWorktypes(
          this.queryParam.workTypeList,
          this.queryParam.jobAlertWorkType
        )

        edmStore.fetchCurrencies({
          currency: this.queryParam.jobAlertSalaryCurrency,
          salaryFrom: this.queryParam.jobAlertSalaryFrom,
          salaryTo: this.queryParam.jobAlertSalaryTo
        })
      })
      .catch((err) => {
        this.setState({ suggestionErrorModal: 'Suggestion Not Found.' })
        this.queryParam.editSuggestionId = ''

        console.log('err', err)
      })
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    this.fetchData()
  }

  componentWillUnmount() {
    const { edmStore } = this.props
    edmStore.categoriesList.forEach((el) => el.reset())
    edmStore.locationsList.forEach((el) => el.reset())
    edmStore.worktypesList.forEach((el) => el.reset())
  }

  fetchData = () => {
    const { agenciesStore, edmStore, rootStore } = this.props

    if (
      !edmStore.categoriesList.length &&
      !edmStore.locationsList.length &&
      !edmStore.worktypesList.length &&
      !edmStore.currencyList.length &&
      rootStore.agency &&
      rootStore.agency.id
    ) {
      if (!agenciesStore.agencyKey) {
        agenciesStore.fetchApiKey(() => {
          if (this.queryParam.editSuggestionId) return
          if (
            !edmStore.categoriesList.length &&
            rootStore.agency &&
            rootStore.agency.id &&
            agenciesStore.agencyKey
          ) {
            this.queryParam.categoriesList.length > 0 ||
            this.queryParam.jobAlertCategory.length > 0
              ? edmStore.fetchSelectedCategories(
                  this.queryParam.categoriesList,
                  this.queryParam.jobAlertCategory
                )
              : edmStore.fetchCategories()
            this.queryParam.locationsList.length > 0 ||
            this.queryParam.jobAlertLocation.length > 0
              ? edmStore.fetchSelectedLocations(
                  this.queryParam.locationsList,
                  this.queryParam.jobAlertLocation
                )
              : edmStore.fetchLocations()
            this.queryParam.workTypeList.length > 0 ||
            this.queryParam.jobAlertWorkType.length > 0
              ? edmStore.fetchSelectedWorktypes(this.queryParam.workTypeList)
              : edmStore.fetchWorktypes()
            edmStore.fetchCurrencies({
              currency: this.queryParam.jobAlertSalaryCurrency,
              salaryFrom: this.queryParam.jobAlertSalaryFrom,
              salaryTo: this.queryParam.jobAlertSalaryTo
            })
          }
        })
      } else {
        if (this.queryParam.editSuggestionId) return
        this.queryParam.categoriesList.length > 0 ||
        this.queryParam.jobAlertCategory.length > 0
          ? edmStore.fetchSelectedCategories(
              this.queryParam.categoriesList,
              this.queryParam.jobAlertCategory
            )
          : edmStore.fetchCategories()
        this.queryParam.locationsList.length > 0 ||
        this.queryParam.jobAlertLocation.length > 0
          ? edmStore.fetchSelectedLocations(
              this.queryParam.locationsList,
              this.queryParam.jobAlertLocation
            )
          : edmStore.fetchLocations()
        this.queryParam.workTypeList.length > 0 ||
        this.queryParam.jobAlertWorkType.length > 0
          ? edmStore.fetchSelectedWorktypes(
              this.queryParam.workTypeList,
              this.queryParam.jobAlertWorkType
            )
          : edmStore.fetchWorktypes()
        edmStore.fetchCurrencies({
          currency: this.queryParam.jobAlertSalaryCurrency,
          salaryFrom: this.queryParam.jobAlertSalaryFrom,
          salaryTo: this.queryParam.jobAlertSalaryTo
        })
      }
    }

    /**
     * find a way to make this request when user is not logged in.
     */
    // console.log('logged In')
    // console.log(rootStore.loggedIn)
    // if (
    //   !edmStore.categoriesList.length &&
    //   rootStore.agency &&
    //   !rootStore.agency.id &&
    //   !rootStore.loggedIn
    // ) {
    //   console.log('this is a test buddy')
    // }
  }

  @action
  saveUrl = () => {
    if (this.formDatas) {
      const res = generateLink(
        '#list',
        this.setQuerySortData(),
        this.props.rootStore.agency.widgetBaseUrl
      )
      const data = res.replace('query', 'rQuery')
      this.copyUrl = `${data.replace('#list', '&isSubscriptionFirst#list')}`
    }
  }

  @action
  setDate = (data) => {
    this.formDatas = data
    this.saveUrl()
  }

  onClickNextStep = () => {
    const { stepIndex } = this.state
    this.setState({ stepIndex: stepIndex + 1 })
    this.queryParam.rQuery = [...this.formDatas.data.keywords]
  }

  onAcceptSuggestion = async () => {
    const { edmStore } = this.props
    const { editSuggestionId } = this.queryParam
    const queryData = this.setQuerySortData()
    queryData.category = selectedFieldsGetIDs(edmStore.categoriesList)

    queryData.location = selectedFieldsById(edmStore.locationsList)
    queryData.worktype = selectedFieldsById(edmStore.worktypesList)

    queryData.salary_currency = queryData.job_alert_salary_currency
    queryData.salary_from = queryData.job_alert_salary_from
    queryData.salary_to = queryData.job_alert_salary_to
    console.log('queryData', queryData)
    await edmStore.acceptSuggestion(editSuggestionId, queryData)
    edmStore.categoriesList.forEach((el) => el.reset())
    edmStore.locationsList.forEach((el) => el.reset())
    edmStore.worktypesList.forEach((el) => el.reset())
    edmStore.resetSalaryFilters()
    this.setState({ displaySuccessModal: true })
    let url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage('SHOW-SUCCESS-SUGGESTION-ACCEPT', url)
  }

  handleCopy = () => {
    toast.success(alertMessages.subscription)
  }

  setPreviousStep = () => {
    const { stepIndex } = this.state
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 })
    }
  }

  previewEmail = (form) => {
    const { edmStore } = this.props
    const queryData = this.setQuerySortData()
    queryData.category = selectedFieldsGetIDs(edmStore.categoriesList)
    queryData.location = selectedFieldsById(edmStore.locationsList)
    queryData.worktype = selectedFieldsById(edmStore.worktypesList)
    edmStore.previewEmail(form, queryData)
  }

  setQuerySortData = () => {
    const params = {}
    const { edmStore } = this.props

    if (this.formDatas) {
      Object.keys(this.formDatas.fields).forEach((key) => {
        if (key === 'keywords') {
          if (
            this.formDatas.data.keywords &&
            this.formDatas.data.keywords.length
          ) {
            params.query = this.formDatas.data.keywords.join(',')
          }
        } else {
          // eslint-disable-next-line default-case
          switch (key) {
            case 'category':
              // params.category = selectedFieldsById(edmStore.categoriesList)
              params.job_alert_category = selectedFieldsById(
                edmStore.categoriesList
              )
              break
            case 'location':
              // params.location = selectedFieldsById(edmStore.locationsList)
              params.job_alert_location = selectedFieldsById(
                edmStore.locationsList
              )
              break
            case 'worktype':
              // params.worktype = selectedFieldsById(edmStore.worktypesList)
              params.job_alert_worktype = selectedFieldsById(
                edmStore.worktypesList
              )
              break
            case 'salaryCurrency':
              params.job_alert_salary_currency = selectedFields(
                edmStore.currencyList
              ).join()
              break
            case 'minSalary':
              params.job_alert_salary_from = edmStore.minSalaryFilter
              break
            case 'maxSalary':
              params.job_alert_salary_to = edmStore.maxSalaryFilter
              break
          }
        }
      })
    }
    return params
  }

  suggest = async (form) => {
    const { edmStore, rootStore } = this.props
    const { loggedIn, user } = rootStore
    const queryData = this.setQuerySortData()
    queryData.category = selectedFieldsGetIDs(edmStore.categoriesList)

    queryData.location = selectedFieldsById(edmStore.locationsList)
    queryData.worktype = selectedFieldsById(edmStore.worktypesList)

    queryData.salary_currency = queryData.job_alert_salary_currency
    queryData.salary_from = queryData.job_alert_salary_from
    queryData.salary_to = queryData.job_alert_salary_to

    if (loggedIn && !user.isReferrer) {
      await rootStore.suggest(form, queryData)
    } else {
      await rootStore.subscribe(form, queryData)
    }
    edmStore.categoriesList.forEach((el) => el.reset())
    edmStore.locationsList.forEach((el) => el.reset())
    edmStore.worktypesList.forEach((el) => el.reset())
    this.queryParam.rQuery = []
    edmStore.resetSalaryFilters()

    if (loggedIn && !user.isReferrer) {
      this.setPreviousStep()
    } else {
      this.setPreviousStep()
      this.setState({ displaySuccessModal: true })
    }
  }

  subscriptionContent = () => {
    if (document.body.clientWidth > 768) {
      return 'Job Alert subscription link'
    }
    return 'copy link'
  }

  stepActiveStyle = (step) => {
    const { stepIndex } = this.state
    const { theme } = this.props
    const isActive = step <= stepIndex
    return {
      color: isActive ? theme.palette.textColor : theme.palette.disabledColor
    }
  }

  setFormData = () => {
    const { edmStore } = this.props

    return [
      this.queryParam.rQuery,
      selectedFields(edmStore.categoriesList),
      selectedFields(edmStore.locationsList),
      selectedFields(edmStore.worktypesList)
    ]
  }

  getSearchList = () => {
    const { stepIndex } = this.state

    if (stepIndex && !this.formDatas) {
      // eslint-disable-next-line array-callback-return
      return Object.values(this.setFormData()).reduce((prev, next) => {
        if (Array.isArray(prev)) {
          return prev.concat(next)
        }
      })
    }
    if (!this.formDatas) {
      return []
    }

    const formDataJSON = this.formDatas?.data
    // eslint-disable-next-line array-callback-return
    const data = formDataJSON ? { ...formDataJSON } : {}
    const minSalary = data.minSalary
    delete data.minSalary
    const maxSalary = data.maxSalary
    delete data.maxSalary
    const list = Object.values(data).reduce((prev, next) => {
      if (typeof next === 'undefined' || next === '') return prev
      if (typeof next === 'number') return prev
      if (Array.isArray(prev)) {
        return prev.concat(next)
      }
    }, [])

    if (Number(minSalary) && Number(maxSalary)) {
      list.push(`Salary Range: ${minSalary} - ${maxSalary}`)
    } else if (Number(minSalary)) {
      list.push(`Min Salary: ${minSalary}`)
    } else if (Number(maxSalary)) {
      list.push(`Max Salary: ${maxSalary}`)
    }

    return list
  }

  isAgencyUser = () => {
    const { rootStore } = this.props
    const { loggedIn } = rootStore
    return loggedIn && !rootStore.user.isReferrer
  }

  closeSuggestionErrorModal = () => {
    let url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage('OPEN-JOB-BOARD', url)
    this.setState({ suggestionErrorModal: '' })
  }

  render() {
    const { theme, edmStore, rootStore, email } = this.props
    const rQuery = this.queryParam?.rQuery
    const { stepIndex } = this.state
    const { loggedIn } = rootStore

    const { agency } = rootStore
    const showSalaryFilter = agency?.showSalaryFilter

    const style = {
      title: { color: theme.palette.primary1Color },
      StepLabel: {
        fontSize: '16px',
        fontWeight: 600,
        paddingLeft: 2
      },
      StepLabel2: {
        marginBottom: 24,
        paddingLeft: 2
      }
    }

    if (
      // !edmStore.categoriesList.length ||
      edmStore.isFetchingCategories ||
      // !edmStore.locationsList.length ||
      edmStore.isFetchingLocations ||
      // !edmStore.worktypesList.length ||
      edmStore.isFetchingWorktypesList ||
      edmStore.isFetchingCurrencyList
    ) {
      return (
        <div className="page-preload-spinner center-xs align-items-center">
          <CircularProgress size={80} color="primary" />
        </div>
      )
    }

    const formData = this.formDatas?.data
    let isNextStepDisabled = false
    if (
      formData &&
      formData.minSalary?.trim() !== '' &&
      formData.maxSalary?.trim() !== '' &&
      Number(formData.minSalary) > Number(formData.maxSalary)
    ) {
      console.log('next step is disabled')
      isNextStepDisabled = true
    }

    if (this.state.displaySuccessModal) {
      return (
        <div className="reports">
          <div className="blur-mask">
            <Dialog
              className="modal"
              fullWidth
              open
              BackdropProps={{ invisible: true }}
              PaperProps={{ elevation: 0 }}
              disableBackdropClick
              disableEscapeKeyDown
            >
              <SuccessfullSubscriptionModal loggedIn={loggedIn} />
            </Dialog>
          </div>
        </div>
      )
    }

    if (this.state.suggestionErrorModal) {
      return (
        <div className="reports">
          <div className="blur-mask">
            <Dialog
              className="modal"
              fullWidth
              open
              BackdropProps={{ invisible: true }}
              PaperProps={{ elevation: 0 }}
              disableBackdropClick
              disableEscapeKeyDown
            >
              <div className="refari-modal-content">
                <h3
                  style={{ color: theme.palette.primary1Color }}
                  id="subSuccess"
                >
                  There was an error fetching the suggestion
                </h3>
                <h4 style={{ paddingBottom: 30 }}>
                  <p>{this.state.suggestionErrorModal}</p>
                </h4>
              </div>

              <div className="refari-justify-center">
                <RaisedButton
                  className="refari-button-flat"
                  onClick={this.closeSuggestionErrorModal}
                  color="primary"
                >
                  Close
                </RaisedButton>
              </div>
            </Dialog>
          </div>
        </div>
      )
    }

    return (
      <div style={{ width: '100%', maxWidth: 625, margin: 'auto' }}>
        <div className="refari-candidate_subscription-section">
          <Stepper activeStep={stepIndex} orientation="vertical">
            <Step key="step-1">
              <StepLabel
                style={{
                  ...style.StepLabel,
                  ...this.stepActiveStyle(0)
                }}
              >
                Job alert criteria
              </StepLabel>
              {this.getSearchList().length > 0 && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    className={classnames('refari-additional-info', {
                      'refari-additional-info__show': stepIndex
                    })}
                    style={
                      stepIndex
                        ? {
                            margin: '10px 13px 0px',
                            minHeight: '22px',
                            flex: 1
                          }
                        : { flex: 1 }
                    }
                  >
                    {this.getSearchList().map((el) => (
                      <Chip
                        key={uuidv1()}
                        style={{
                          marginRight: 5,
                          marginBottom: 5,
                          cursor: 'pointer',
                          color: '#fff',
                          backgroundColor: theme.palette.primary1Color
                        }}
                        onClick={this.setPreviousStep}
                        label={el}
                      />
                    ))}
                  </div>
                  {stepIndex ? (
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: '12px', textTransform: 'none' }}
                        onClick={this.setPreviousStep}
                        size="small"
                      >
                        Edit Criteria
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}
              <StepContent
                className="refari-step-content refari-subscribe-tab-content"
                transitionDuration={0}
                style={{ border: 'none' }}
              >
                <SubscriptionSelectForm
                  rootStore={rootStore}
                  edmStore={edmStore}
                  setDate={this.setDate}
                  setNewUrl={this.saveUrl}
                  rQuery={rQuery}
                  showSalaryFilter={showSalaryFilter}
                />
                <div className="refari-w-row refari-justify-start refari-align-items-center m_top_2x">
                  {this.queryParam.editSuggestionId ? (
                    <RaisedButton
                      style={{
                        marginRight: '15px',
                        backgroundColor: isNextStepDisabled
                          ? 'rgba(0, 0, 0, 0.12)'
                          : theme.palette.primary1Color,
                        color: isNextStepDisabled
                          ? 'rgba(0, 0, 0, 0.26)'
                          : '#fff'
                      }}
                      onClick={this.onAcceptSuggestion}
                      className="refari-button-raised"
                      disabled={isNextStepDisabled}
                    >
                      Accept
                    </RaisedButton>
                  ) : (
                    <RaisedButton
                      style={{
                        marginRight: '15px',
                        backgroundColor: isNextStepDisabled
                          ? 'rgba(0, 0, 0, 0.12)'
                          : theme.palette.primary1Color,
                        color: isNextStepDisabled
                          ? 'rgba(0, 0, 0, 0.26)'
                          : '#fff'
                      }}
                      onClick={this.onClickNextStep}
                      className="refari-button-raised"
                      disabled={isNextStepDisabled}
                    >
                      Next
                    </RaisedButton>
                  )}
                  {this.isAgencyUser() && !this.queryParam.editSuggestionId && (
                    <CopyToClipboard
                      text={this.copyUrl}
                      onCopy={this.handleCopy}
                    >
                      <RaisedButton
                        style={{
                          backgroundColor: theme.palette.primary1Color,
                          color: '#fff'
                        }}
                        className="refari-button-raised"
                      >
                        {this.subscriptionContent()}
                      </RaisedButton>
                    </CopyToClipboard>
                  )}
                </div>
              </StepContent>
            </Step>
            {!this.queryParam.editSuggestionId && (
              <Step style={{ marginTop: 0 }} key="step-2">
                <StepLabel
                  style={{
                    ...style.StepLabel,
                    ...style.StepLabel2,
                    ...this.stepActiveStyle(1)
                  }}
                >
                  General details
                </StepLabel>
                <StepContent
                  className="refari-step-content"
                  transitionDuration={0}
                >
                  <div>
                    <Suggest
                      suggest={this.suggest}
                      createSubscription
                      previewEmail={this.previewEmail}
                      setPreviousStep={this.setPreviousStep}
                      theme={theme}
                      rootStore={rootStore}
                      edmStore={edmStore}
                      subscriptionContent={this.subscriptionContent}
                      text={this.copyUrl}
                      email={email}
                    />
                  </div>
                </StepContent>
              </Step>
            )}
          </Stepper>
        </div>
      </div>
    )
  }
}

export default withTheme(CreateJobAlert)
