/* eslint-disable no-return-assign */
import { observable, action, runInAction } from 'mobx'
import * as axios from 'axios'
import API from '../utils/API'
import Logger from '../utils/Logger'
import { ApiRoutes } from '../utils/Urls'
import { Job } from '../models/JobsEdm'
import { FilterCheck } from '../components/shared/modals/filters/FilterCheck'

export class EdmStore {
  @observable jobsList = []
  @observable selectedJobList = []
  @observable modal = false
  @observable iconsList = []
  @observable selectedIcon = {}
  @observable socialLink = null
  @observable socialModal = false
  @observable preview = null
  @observable previewStateDate = {}

  @observable categoriesList = []
  @observable isFetchingCategories = false
  @observable isComponentUpdated = false
  @observable locationsList = []
  @observable isFetchingLocations = false
  @observable worktypesList = []
  @observable isFetchingWorktypesList = false
  @observable modalSubscription = false
  @observable jobAlertLink = ''
  @observable emailPreview = null
  @observable isOpenEmailPreviewModal = false

  @observable isFetchingCurrencyList = false
  @observable currencyList = []

  @observable minSalaryFilter
  @observable maxSalaryFilter

  @observable suggestionData = null

  @action
  handleJobSearch = async (name = '') => {
    try {
      if (!axios.defaults.headers['Api-Key']) {
        return []
      }
      const res = await API.getData(ApiRoutes.dashboard.jobs.search(name))
      const jobsList = res.data.results.map((el) => new Job(el))
      const returnResult = jobsList.filter((el) => {
        const faund = this.selectedJobList.find((item) => item.id === el.id)
        if (!faund) {
          return el
        }
        return null
      })
      runInAction(() => (this.jobsList = returnResult.filter((el) => el)))
      return returnResult
    } catch (e) {
      Logger.error(e, 'handleJobSearch')
    }
  }

  @action
  fetchSocial = async () => {
    try {
      const res = await API.getData(ApiRoutes.dashboard.jobs.social)
      runInAction(() => (this.socialLink = res.data))
    } catch (e) {
      Logger.error(e, 'fetchSocial')
    }
  }

  @action
  setSocial = async (data) => {
    try {
      const res = await API.postData(ApiRoutes.dashboard.jobs.social, data.data)
      runInAction(() => (this.socialLink = res.data))
    } catch (e) {
      Logger.error(e, 'fetchSocial')
    }
  }

  @action
  selectJob = (job) => {
    if (this.selectedJobList.length === 10) {
      return
    }
    const exist = this.selectedJobList.find((el) => el.id === job.id)
    if (!exist) {
      this.selectedJobList = [...this.selectedJobList, job]
      const res = this.jobsList.filter((el) => el.id !== job.id)
      this.jobsList = res
    }
  }

  @action
  fetchCategories = async () => {
    let response
    try {
      this.isFetchingCategories = true
      response = await API.getData(ApiRoutes.subscription.categories)
      runInAction(() => {
        this.categoriesList = response.data.map(
          (el) => new FilterCheck(el, response.data.length)
        )
      })
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingCategories', false)
    }
  }

  @action
  fetchSelectedCategories = async (value, categorySelectedIds) => {
    try {
      this.isFetchingCategories = true
      const filter = Array.isArray(value)
        ? value.join('|')
        : value
        ? value.replace(/%2C/g, '|')
        : ''
      const response = await API.getData(
        ApiRoutes.subscription.selectedCategories(filter)
      )
      runInAction(() => {
        if (this.categoriesList.length === 0)
          this.categoriesList = response.data.map(
            (el) => new FilterCheck(el, response.data.length)
          )
        response.data.forEach((selectedItem) => {
          this.categoriesList.forEach((el) => {
            if (el.key === +selectedItem.id && selectedItem.selected) {
              el.toggle()
              return
            }
            if (el.children.data) {
              el.children.data.forEach((item) => {
                if (item.key === +selectedItem.id && selectedItem.selected) {
                  item.applyFilter()
                }
              })
            }
          })
          if (selectedItem.children.length > 0) {
            selectedItem.children.forEach((slitem) => {
              this.categoriesList.forEach((el) => {
                if (el.key === +slitem.id && slitem.selected) {
                  el.toggle()
                  return
                }
                if (el.children.data) {
                  el.children.data.forEach((item) => {
                    if (item.key === +slitem.id && slitem.selected) {
                      item.applyFilter()
                    }
                  })
                }
              })
            })
          }
        })
        /**
         * @note select category on the basis of categorySelectedIds
         */
        if (
          Array.isArray(categorySelectedIds) &&
          categorySelectedIds.length > 0
        ) {
          /**
           * parse it and if value includes then update selected:true
           */
          this.categoriesList.forEach((category) => {
            if (
              categorySelectedIds.includes(category.key + '') &&
              !category.selected
            ) {
              category.toggle()
            }
            /**
             * @note handle the cases where data.children is there
             */
            const childrens = category?.children?.data
            if (childrens && Array.isArray(childrens)) {
              childrens.forEach((child) => {
                if (
                  categorySelectedIds.includes(child?.key + '') &&
                  !child?.selected
                ) {
                  child.toggle()
                }
              })
            }
          })
        }
      })
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingCategories', false)
    }
  }

  @action
  fetchLocations = async () => {
    let response
    try {
      this.isFetchingLocations = true
      response = await API.getData(ApiRoutes.subscription.locations)
      runInAction(() => {
        this.locationsList = response.data.map(
          (el) => new FilterCheck(el, response.data.length)
        )
      })
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingLocations', false)
    }
  }

  @action
  fetchSelectedLocations = async (value, locationSelectedIds) => {
    try {
      const filter = Array.isArray(value)
        ? value.join('|')
        : value
        ? value.replace(/%2C/g, '|')
        : ''

      this.isFetchingLocations = true

      const response = await API.getData(
        ApiRoutes.subscription.selectedLocations(filter)
      )
      runInAction(() => {
        if (this.locationsList.length === 0)
          this.locationsList = response.data.map(
            (el) => new FilterCheck(el, response.data.length)
          )
        response.data.forEach((selectedItem) => {
          this.locationsList.forEach((el) => {
            /**
             * backend is not sending the appropriate response with selected: true
             * So when filter is present toggle the selected item if it present in response
             * If filter is not present then toggle on the basis of selected:true
             *
             * below functionality part commented is now, since backend is responding with appropriate response
             */
            // const selected = !filter ? selectedItem.selected : true
            // if (el.key === +selectedItem.id && selected) {
            //   el.toggle()
            //   return
            // }
            if (el.children.data) {
              el.children.data.forEach((item) => {
                if (item.key === +selectedItem.id && selectedItem.selected) {
                  item.applyFilter()
                }
              })
            }
          })
        })
        /**
         * @note select location on the basis of locationSelectedIds
         */
        if (
          Array.isArray(locationSelectedIds) &&
          locationSelectedIds.length > 0
        ) {
          this.locationsList.forEach((location) => {
            if (
              locationSelectedIds.includes(location?.key + '') &&
              !location?.selected
            ) {
              location.toggle()
            }
            const childrens = location?.children?.data
            if (childrens && Array.isArray(childrens)) {
              childrens.forEach((child) => {
                if (
                  locationSelectedIds.includes(child?.key + '') &&
                  !child?.selected
                ) {
                  child.toggle()
                }
              })
            }
          })
        }
      })
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingLocations', false)
    }
  }

  @action
  fetchWorktypes = async () => {
    let response
    try {
      this.isFetchingWorktypesList = true
      response = await API.getData(ApiRoutes.subscription.worktypes)
      runInAction(() => {
        this.worktypesList = response.data.map(
          (el) => new FilterCheck(el, response.data.length)
        )
      })
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingWorktypesList', false)
    }
  }

  @action
  fetchSelectedWorktypes = async (value, workTypeSelectedIds) => {
    try {
      const filter = Array.isArray(value)
        ? value.join('|')
        : value
        ? value.replace(/%2C/g, '|')
        : ''
      this.isFetchingWorktypesList = true
      const response = await API.getData(
        ApiRoutes.subscription.selectedWorktypes(filter)
      )
      runInAction(() => {
        if (this.worktypesList.length === 0)
          this.worktypesList = response.data.map(
            (el) => new FilterCheck(el, response.data.length)
          )
        response.data.forEach((selectedItem) => {
          this.worktypesList.forEach((el) => {
            /**
             * backend is not sending the appropriate response with selected: true
             * So when filter is present toggle the selected item if it present in response
             * If filter is not present then toggle on the basis of selected:true
             *
             * below functionality part commented is now, since backend is responding with appropriate response
             */
            // const selected = !filter ? selectedItem.selected : true
            // if (el.key === +selectedItem.id && selected) {
            //   el.toggle()
            //   return
            // }
            if (el.children.data) {
              el.children.data.forEach((item) => {
                if (item.key === +selectedItem.id && selectedItem.selected) {
                  item.applyFilter()
                }
              })
            }
          })
        })
        if (
          Array.isArray(workTypeSelectedIds) &&
          workTypeSelectedIds.length > 0
        ) {
          this.worktypesList.forEach((workType) => {
            if (
              workTypeSelectedIds.includes(workType?.key + '') &&
              !workType?.selected
            ) {
              workType.toggle()
            }
            const childrens = workType?.children?.data
            if (childrens && Array.isArray(childrens)) {
              childrens.forEach((child) => {
                if (
                  workTypeSelectedIds.includes(child?.key + '') &&
                  !child?.selected
                ) {
                  child.toggle()
                }
              })
            }
          })
        }
      })
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingWorktypesList', false)
    }
  }

  @action
  setSalaryRange = (salaryFrom, salaryTo) => {
    this.minSalaryFilter = salaryFrom
    this.maxSalaryFilter = salaryTo
  }

  @action
  fetchCurrencies = async (options) => {
    const selectedCurrency = options?.currency || undefined
    const salaryFrom = +options?.salaryFrom || undefined
    const salaryTo = +options?.salaryTo || undefined

    let response
    try {
      this.isFetchingCurrencyList = true
      response = await API.getData(ApiRoutes.subscription.currencies)
      runInAction(() => {
        this.currencyList = response.data.map(
          (el) => new FilterCheck(el, response.data.length)
        )
        if (selectedCurrency) {
          this.currencyList = this.currencyList.map((item) => {
            if (item.label === selectedCurrency) {
              this.setSalaryRange(salaryFrom, salaryTo)
              return { ...item, selected: true }
            }
            return { ...item, selected: false }
          })
        }
      })
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingCurrencyList', false)
    }
  }

  @action
  setSuggestion = async (id) => {
    try {
      const response = await API.getData(ApiRoutes.suggestions.fetch(id))
      if (response.status === 200) {
        const data = {
          categories:
            response.data.category_names &&
            response.data.category_names.map((el) => el + ''),
          locations:
            response.data.location_names &&
            response.data.location_names.map((el) => el + ''),
          worktypes:
            response.data.worktype_names &&
            response.data.worktype_names.map((el) => el + ''),
          query: response.data.query,
          keywords: response.data.keywords?.split(','),
          salary_currency: response.data.salary_currency,
          salary_from: response.data.salary_from,
          salary_to: response.data.salary_to,
          interval: response.data.interval,
          is_accepted: response.data.is_accepted
        }
        runInAction(() => {
          this.suggestionData = data
        })

        return data
      }
    } catch (e) {
      console.log(e)
    }
  }

  @action
  acceptSuggestion = async (id, formData) => {
    try {
      const data = {
        interval: this.suggestionData.interval,
        keywords: formData.query,
        categories: formData.category,
        locations: formData.location,
        worktypes: formData.worktype,
        salary_currency: formData.salary_currency,
        salary_from: formData.salary_from,
        salary_to: formData.salary_to
      }
      const response = await API.postData(ApiRoutes.suggestions.edit(id), data)
      if (response.status === 200) return response.data
    } catch (e) {
      console.log(e)
    }
  }

  @action
  removeJob = (job) => {
    const res = this.selectedJobList.filter((el) => el.id !== job.id)
    this.selectedJobList = res
    this.jobsList = [...this.jobsList, job]
  }

  @action
  addNewIcon = (icon) => {
    const exist = this.iconsList.find((el) => el.id === icon.id)
    if (exist) {
      const res = this.iconsList.map((el) => {
        if (el.id === icon.id) {
          return icon
        }
        return el
      })
      this.iconsList = res
    } else {
      this.iconsList = [...this.iconsList, icon]
    }
    this.modal = true
  }

  @action
  editIcon = (icon) => {
    this.selectedIcon = icon
    this.modal = true
  }

  @action
  removeIcon = (icon) => {
    const res = this.iconsList.filter((el) => el.id !== icon.id)
    this.iconsList = res
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }

  @action
  previewEmail = async (form, filtersData) => {
    try {
      filtersData = {
        categories: filtersData.category,
        locations: filtersData.location,
        worktypes: filtersData.worktype,
        query: filtersData.query
      }
      const formData = { ...form.data }
      if (formData.message) {
        formData.message = formData.message.toString('html')
      }

      if (formData.email === '') {
        delete formData.email
      }

      const data = {
        ...filtersData,
        ...formData
      }
      const response = await API.postData(
        ApiRoutes.suggestions.emailPreview,
        data
      )
      runInAction(() => {
        this.emailPreview = response.data
        this.isOpenEmailPreviewModal = true
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  resetSalaryFilters = () => {
    this.currencyList.forEach((el) => {
      el.selected = false
    })
    this.minSalaryFilter = undefined
    this.maxSalaryFilter = undefined
  }

  @action
  toggleEmailPreviewModal = () => {
    this.isOpenEmailPreviewModal = !this.isOpenEmailPreviewModal
  }
}

export default new EdmStore()
