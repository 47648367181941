import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Switch } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { PublicRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'
import JobAlert from '../tools/CreateJobAlert/JobAlert'
import qp from 'query-parse'
import * as axios from 'axios'

@inject('rootStore')
@inject('agenciesStore')
@inject('edmStore')
@observer
class CreateJobAlert extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      initTab: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired
    }),
    history: PropTypes.object.isRequired,
    agenciesStore: PropTypes.shape({
      availableSeveralAgencies: PropTypes.bool.isRequired,
      activeAgency: PropTypes.object.isRequired,
      isFetch: PropTypes.bool.isRequired
    })
  }
  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.rootStore.initTab(pathname)
    const queryData = qp.toObject(props.history.location.search.slice(1))
    this.queryParam = {
      categoriesList: queryData.category?.split(',') || [],
      locationsList: queryData.location?.split(',') || [],
      consultantList: queryData.consultant?.split(',') || [],
      workTypeList: queryData.worktype?.split(',') || [],
      apiKey: queryData.apiKey,
      themePrimaryColor: queryData.themeColor
    }

    const agencyStore = props.agenciesStore
    agencyStore.setData('agencyKey', this.queryParam.apiKey)
    agencyStore.setData('agencyApiKey', this.queryParam.apiKey)
    axios.defaults.headers['Api-Key'] = this.queryParam.apiKey
    /**
     * setup primary color
     */
    if (this.queryParam.themePrimaryColor)
      props.rootStore.setData('branding', {
        ...props.rootStore.agency.branding,
        primaryColor: this.queryParam.themePrimaryColor
      })
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    // this.fetchData()
  }

  componentWillUnmount() {
    const { edmStore } = this.props
    edmStore.categoriesList.forEach((el) => el.reset())
    edmStore.locationsList.forEach((el) => el.reset())
    edmStore.worktypesList.forEach((el) => el.reset())
  }

  fetchData = () => {
    const { edmStore, rootStore } = this.props
    /**
     * @note adding this will break the application
     */
    // this.queryParam.categoriesList.length > 0
    //   ? edmStore.fetchSelectedCategories(this.queryParam.categoriesList)
    //   : edmStore.fetchCategories()
    // this.queryParam.locationsList.length > 0
    //   ? edmStore.fetchSelectedLocations(this.queryParam.locationsList)
    //   : edmStore.fetchLocations()
    // this.queryParam.workTypeList.length > 0
    //   ? edmStore.fetchSelectedWorktypes(this.queryParam.workTypeList)
    //   : edmStore.fetchWorktypes()

    /**
     * @note make a call to widget agency endpoint to setup agency
     */

    rootStore.fetchWidgetAgency()
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match
    } = this.props
    // return 'Suggest a job alert'
    return (
      <div className="settings">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div className="container">
            <div
              className={`settings-container ${
                this.props.location &&
                (this.props.location.pathname.includes('question-config') ||
                  this.props.location.pathname.includes('pitch-me'))
                  ? 'question-mapping-container'
                  : ''
              }`}
            >
              {/* <Switch>
                <PublicRoute
                  exact
                  path={`${match.url}${URLs.createJobAlert.path}`}
                  //   component={asyncComponent(() =>
                  //     import('../tools/CreateJobAlert/CreateJobAlert')
                  //   )}
                  component={CJobAlert}
                />
              </Switch> */}
              <JobAlert {...this.props} isPublic />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(CreateJobAlert)
