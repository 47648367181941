import React, { Component } from 'react'

import TopRecImage from 'src/assets/images/toprec-img.svg'

type UpsellContentProps = {
  featureName: string
}

class UpsellContent extends Component<UpsellContentProps> {
  render() {
    const { featureName } = this.props

    return (
      <div className="modal-content">
        <div className="toprec-box">
          <div className="toprec-img">
            <img src={TopRecImage} className="img-fluid" alt="" />
          </div>
          <div className="toprec-cont">
            <h5>You&apos;re not using {featureName}</h5>
            <p>
              To view this page, Please get in contact to your system
              administrator to enable the {featureName} feature.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default UpsellContent
