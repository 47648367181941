/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import { CircularProgress, Dialog } from '@material-ui/core'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'
import qp from 'query-parse'
import Logger from 'src/utils/Logger'
import UpsellContent from 'src/components/shared/upsellComponents/UpsellContent'
import UpsellBg from 'src/components/shared/upsellComponents/UpsellBg'

@inject('agenciesStore')
@inject('rootStore')
@observer
class Reports extends React.Component {
  constructor(props) {
    super(props)
    const { pathname } = props.history.location
    props.rootStore.initTab(pathname)
  }

  onTabChange = (link) => {
    this.props.agenciesStore.progressNew()
    this.props.rootStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }

  setAgency = async (companySlug) => {
    try {
      if (companySlug) {
        const agency = this.props.rootStore.getAgencyBySlugName(companySlug)
        this.props.agenciesStore.changeAgency(agency)
      }
    } catch (e) {
      Logger.error('Agency Switch Unsuccessful')
    }
  }

  render() {
    const {
      agenciesStore: { isFetch },
      rootStore: { agency },
      match
    } = this.props

    if (isFetch || !agency || (agency && !agency.id)) {
      return (
        <div className="page-preload-spinner center-xs align-items-center w-100">
          <CircularProgress size={80} color="primary" />
        </div>
      )
    }

    const queryData = qp.toObject(this.props.location.search.slice(1))
    const queryParam = {
      company: queryData.company || queryData['amp;company']
    }
    this.setAgency(queryParam.company)

    return (
      <div className="reports">
        {agency && agency.id && !agency.enableTestimonials && (
          <>
            <div className="blur-mask">
              <Dialog
                className="blur-mask-modal-container modal"
                fullWidth
                open
                BackdropProps={{
                  className: 'blur-mask-modal-container-overlay'
                }}
                disableBackdropClick
                disableEscapeKeyDown
              >
                <UpsellContent featureName="TopRec" />
              </Dialog>
            </div>
            <UpsellBg />
          </>
        )}
        {agency && agency.id && agency.enableTestimonials && (
          <div className="reports-container">
            <Switch>
              <PrivateRoute
                exact
                path={`${match.url}${URLs.toprec.children.ratings.path}`}
                component={asyncComponent(() => import('./ratings'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.toprec.children.feedback.path}`}
                component={asyncComponent(() => import('./feedback'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.toprec.children.settings.path}`}
                component={asyncComponent(() => import('./settings'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.toprec.children.placements.path}`}
                component={asyncComponent(() => import('./placements'))}
              />
            </Switch>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(Reports)
